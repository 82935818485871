.matchmaking {
    background: #4BB34B;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation: 1s all;
    transition: 1s all;

    &-finded {
        background: #e64646 !important;
    }

    &__timer {
        padding: 10px 15px;
        background: white;
        font-size: 24px;
        font-weight: bold;
        border-radius: 45px;
        position: absolute;
        top: calc(var(--safe-area-inset-top) + 75px);
        &__content {
            color: #4BB34B;

            &-finded {
                color: #e64646 !important;
            }
        }
    }

    &__bottom {
        width: 300px;
        position: absolute;
        bottom: calc(var(--safe-area-inset-bottom) + 50px);
        display: flex;
        flex-direction: column;
        align-items: center;

        &-ads {
            bottom: calc(var(--safe-area-inset-bottom) + 100px) !important;
        }

        .Button {
            margin-top: 25px;
        }
    }

    &__text {
        color: white;
        font-size: 22px;
        font-weight: bold;
    }
}
